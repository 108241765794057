import "chart.js";
import { Component, h } from "preact";
import { ColumnChart } from "react-chartkick";

export default class CategoryChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taps_data: [],
            views_data: [],
            taps: 0,
            views: 0,
            picker_open: false,
            tab: "taps",
            date_ranges: [7, 14, 21, 30, 45, 60, 90],
            custom_date_ranges: [
                "Today",
                "Yesterday",
                "This week",
                "Last week",
                "This month",
                "Last month",
            ],
            selected_day_range: 7,
            loading: true,
        };
    }

    componentDidMount = () => {
        const saved_stats = sessionStorage.getItem(
            `category_stats_${this.props.id}`
        );
        if (saved_stats) {
            this.setState(JSON.parse(saved_stats), this._get_settings_and_sync);
        } else {
            this._get_settings_and_sync();
        }
    };

    _get_settings_and_sync = () => {
        this.setState({ loading: true });
        fetch(`/stats/settings`).then((response) => {
            if (response.status === 200) {
                response.json().then((result) => {
                    if (result.data !== null) {
                        this.setState(
                            {
                                selected_day_range: result.days,
                                tab: result.tab,
                            },
                            this._get_data
                        );
                    }
                });
            }
        });
    };

    _get_data = () => {
        this.setState({ loading: true }, () => {
            fetch(
                `/stats/category/${this.props.id}?days=${this.state.selected_day_range}&tab=${this.state.tab}`
            ).then((response) => {
                if (response.status === 200) {
                    response.json().then((result) => {
                        if (result.data !== null && result.data.length > 0) {
                            this.setState(
                                {
                                    taps_data: result.data,
                                    taps: result.taps,
                                    selected_day_range: result.day_range,
                                    tab: result.selected_tab,
                                    loading: false,
                                },
                                this._save_stats
                            );
                        }
                    });
                }
            });
        });
        this.setState({ loading: true }, () => {
            fetch(
                `/stats/category_views/${this.props.id}?days=${this.state.selected_day_range}&tab=${this.state.tab}`
            ).then((response) => {
                if (response.status === 200) {
                    response.json().then((result) => {
                        if (result.data !== null && result.data.length > 0) {
                            this.setState(
                                {
                                    views_data: result.data,
                                    views: result.views,
                                    selected_day_range: result.day_range,
                                    tab: result.selected_tab,
                                    loading: false,
                                },
                                this._save_stats
                            );
                        }
                    });
                }
            });
        });
    };

    _save_stats = () => {
        sessionStorage.setItem(
            `category_stats_${this.props.id}`,
            JSON.stringify(this.state)
        );
    };

    _render_date_picker_button = () => {
        return (
            <button
                onMouseLeave={() => this.setState({ picker_open: false })}
                onMouseEnter={() => this.setState({ picker_open: true })}
                onClick={() =>
                    this.setState({ picker_open: !this.state.picker_open })
                }
                class="p-1 flex items-center flex-row rounded border px-2 bg-gray-100 dark:bg-gray-900 shadow"
            >
                <p class="mr-2">
                    Set day range (
                    <b>{`${this.state.selected_day_range}${
                        !isNaN(this.state.selected_day_range) ? " days" : ""
                    }`}</b>
                    )
                </p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <rect
                        x="3"
                        y="4"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                    ></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                </svg>
            </button>
        );
    };

    _render_date_picker_options = () => {
        const ranges = this.state.date_ranges.map((days) => {
            const is_selected =
                String(days) === String(this.state.selected_day_range);
            return (
                <button
                    class={`ml-1 mr-1 ${
                        is_selected ? "bg-green-300 dark:bg-green-500" : ""
                    } rounded p-1 hover:bg-gray-400 transition_fast`}
                    onClick={() => {
                        this.setState(
                            { selected_day_range: days, picker_open: false },
                            this._get_data
                        ),
                            this._save_stats();
                    }}
                >
                    {`${days} days`}
                </button>
            );
        });
        const custom_ranges = this.state.custom_date_ranges.map((range) => {
            const is_selected = range === this.state.selected_day_range;
            return (
                <button
                    class={`ml-1 mr-1 mb-2 ${
                        is_selected ? "bg-green-300 dark:bg-green-500" : ""
                    } rounded p-1 hover:bg-gray-400 transition_fast`}
                    onClick={() => {
                        this.setState(
                            { selected_day_range: range, picker_open: false },
                            this._get_data
                        ),
                            this._save_stats();
                    }}
                >
                    {`${range}`}
                </button>
            );
        });
        return (
            <div
                onMouseLeave={() => this.setState({ picker_open: false })}
                onMouseEnter={() => this.setState({ picker_open: true })}
                class={`flex flex-col transition_fast absolute p-2 bg-gray-200 dark:bg-gray-900 z-20 rounded right-0 shadow-lg ${
                    this.state.picker_open
                        ? "nav_rotate opacity-100 visible"
                        : "nav_rotate_stop opacity-0 invisible"
                }`}
                style="top: 2em"
            >
                <div class="flex flex-row pb-2 border-b dark:border-gray-300 border-gray-600 mb-2">
                    {ranges}
                </div>
                <div class="flex flex-row flex-wrap -mb-2">{custom_ranges}</div>
            </div>
        );
    };

    _render_top_tapped_ads = () => {
        if (this.state.taps > 0) {
            const ads = this.state.taps_data
                .filter((ad) => ad.total_taps > 0)
                .sort((a, b) => {
                    return b.total_taps - a.total_taps;
                });
            if (ads?.length) {
                const rendered_ads = ads.map((item) => {
                    return (
                        <tr>
                            <td class="px-2 py-2 pr-0 whitespace-no-wrap border-b border-gray-300 dark:border-gray-700">
                                <a
                                    href={`/ads/${item.ad.id}`}
                                    class="flex flex-col justify-center content-center items-center"
                                >
                                    {item.ad.image_url ? (
                                        <img
                                            class="lazy"
                                            loading="lazy"
                                            style="max-width: 50px; border-radius: 17.667%; width: 50px"
                                            src={item.ad.image_url}
                                        />
                                    ) : (
                                        <svg
                                            class="text-brand-orange"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <rect
                                                x="9"
                                                y="9"
                                                width="13"
                                                height="13"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                    )}
                                </a>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-300 dark:border-gray-700">
                                <a href={`/ads/${item.ad.id}`}>
                                    <div class="flex flex-col">
                                        <div class="text font-semibold text-gray-900 dark:text-gray-200 flex flex-row items-center">
                                            <div
                                                class="rounded mr-2"
                                                style={`height: 15px; width: 15px; background-color: #${item.color};`}
                                            ></div>
                                            {item.ad.title}
                                        </div>
                                        <div class="text-sm text-gray-500 dark:text-gray-200 mt-2">
                                            ID:{" "}
                                            <span class="p-1 py-0 bg-gray-200 dark:bg-gray-700 rounded">
                                                {item.ad.id}
                                            </span>
                                            {item.ad.tag !== null &&
                                            item.ad.tag !== "" ? (
                                                <span class="px-1 ml-2 inline-flex font-semibold rounded-full bg-orange-300 text-orange-900 items-center">
                                                    <svg
                                                        class="mr-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="12"
                                                        height="12"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                                                        <line
                                                            x1="7"
                                                            y1="7"
                                                            x2="7.01"
                                                            y2="7"
                                                        ></line>
                                                    </svg>
                                                    {item.ad.tag}
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </a>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-300 dark:border-gray-700">
                                <span class="px-2 inline-flex font-semibold rounded-full bg-green-300 text-green-900">
                                    {item.total_taps}&nbsp;
                                    <span class="font-light">
                                        {item.total_taps > 1 ? "taps" : "tap"}
                                    </span>
                                </span>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-300 dark:border-gray-700 text-gray-500 font-medium">
                                <a
                                    href={`/ads/${item.ad.id}`}
                                    class="text-brand dark:text-purple-600 hover:text-indigo-900 dark:hover:text-indigo-500"
                                >
                                    View
                                </a>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-300 dark:border-gray-700 text-sm">
                                <a
                                    href={`/ads/${item.ad.id}/edit`}
                                    class="text-indigo-300 hover:text-indigo-900 dark:hover:text-indigo-500"
                                >
                                    Edit
                                </a>
                            </td>
                        </tr>
                    );
                });
                return (
                    <div class="flex flex-col w-full overflow-x-auto">
                        <table>{rendered_ads}</table>
                    </div>
                );
            }
        }
        return null;
    };

    _render_top_viewed_ads = () => {
        if (this.state.views > 0) {
            const ads = this.state.views_data
                .filter((ad) => ad.total_views > 0)
                .sort((a, b) => {
                    return b.total_taps - a.total_taps;
                });
            if (ads?.length) {
                const rendered_ads = ads.map((item) => {
                    return (
                        <tr>
                            <td class="px-2 py-2 pr-0 whitespace-no-wrap border-b border-gray-300 dark:border-gray-700">
                                <a
                                    href={`/ads/${item.ad.id}`}
                                    class="flex flex-col justify-center content-center items-center"
                                >
                                    {item.ad.image_url ? (
                                        <img
                                            class="lazy"
                                            loading="lazy"
                                            style="max-width: 50px; border-radius: 17.667%; width: 50px"
                                            src={item.ad.image_url}
                                        />
                                    ) : (
                                        <svg
                                            class="text-brand-orange"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <rect
                                                x="9"
                                                y="9"
                                                width="13"
                                                height="13"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                    )}
                                </a>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-300 dark:border-gray-700">
                                <a href={`/ads/${item.ad.id}`}>
                                    <div class="flex flex-col">
                                        <div class="text font-semibold text-gray-900 dark:text-gray-200 flex flex-row items-center">
                                            <div
                                                class="rounded mr-2"
                                                style={`height: 15px; width: 15px; background-color: #${item.color};`}
                                            ></div>
                                            {item.ad.title}
                                        </div>
                                        <div class="text-sm text-gray-500 dark:text-gray-200 mt-2">
                                            ID:{" "}
                                            <span class="p-1 py-0 bg-gray-200 dark:bg-gray-700 rounded">
                                                {item.ad.id}
                                            </span>
                                            {item.ad.tag !== null &&
                                            item.ad.tag !== "" ? (
                                                <span class="px-1 ml-2 inline-flex font-semibold rounded-full bg-orange-300 text-orange-900 items-center">
                                                    <svg
                                                        class="mr-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="12"
                                                        height="12"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                                                        <line
                                                            x1="7"
                                                            y1="7"
                                                            x2="7.01"
                                                            y2="7"
                                                        ></line>
                                                    </svg>
                                                    {item.ad.tag}
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </a>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-300 dark:border-gray-700">
                                <span class="px-2 inline-flex font-semibold rounded-full bg-green-300 text-green-900">
                                    {item.total_views}&nbsp;
                                    <span class="font-light">
                                        {item.total_views > 1
                                            ? "views"
                                            : "view"}
                                    </span>
                                </span>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-300 dark:border-gray-700 text-gray-500 font-medium">
                                <a
                                    href={`/ads/${item.ad.id}`}
                                    class="text-brand dark:text-purple-600 hover:text-indigo-900 dark:hover:text-indigo-500"
                                >
                                    View
                                </a>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-300 dark:border-gray-700 text-sm">
                                <a
                                    href={`/ads/${item.ad.id}/edit`}
                                    class="text-indigo-300 hover:text-indigo-900 dark:hover:text-indigo-500"
                                >
                                    Edit
                                </a>
                            </td>
                        </tr>
                    );
                });
                return (
                    <div class="flex flex-col w-full overflow-x-auto">
                        <table>{rendered_ads}</table>
                    </div>
                );
            }
        }
        return null;
    };

    _render_tab_section = () => {
        switch (this.state.tab) {
            case "taps":
                return this._render_top_tapped_ads();
            case "views":
                return this._render_top_viewed_ads();
            default:
                return this._render_top_tapped_ads();
        }
    };

    _return_chart_data = () => {
        switch (this.state.tab) {
            case "taps":
                return this.state.taps_data;
            case "views":
                return this.state.views_data;
            default:
                return this.state.taps_data;
        }
    };

    _return_stats_total = () => {
        switch (this.state.tab) {
            case "taps":
                return this.state.taps;
            case "views":
                return this.state.views;
            default:
                return this.state.taps;
        }
    };

    _render_tab_switcher = () => {
        switch (this.state.tab) {
            case "taps":
                return (
                    <button
                        className="p-1 rounded px-2 bg-gray-100 dark:bg-gray-700 shadow flex flex-row items-center"
                        onClick={() => {
                            this.setState({ tab: "views" }, this._get_data),
                                this._save_stats();
                        }}
                    >
                        <p>
                            Show <b>views</b>
                        </p>
                        <svg
                            className="ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                    </button>
                );
            case "views":
                return (
                    <button
                        className="p-1 rounded px-2 bg-gray-100 dark:bg-gray-700 shadow flex flex-row items-center"
                        onClick={() => {
                            this.setState({ tab: "taps" }, this._get_data),
                                this._save_stats();
                        }}
                    >
                        <p>
                            Show <b>taps</b>
                        </p>
                        <svg
                            className="ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <circle cx="12" cy="12" r="2"></circle>
                            <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"></path>
                        </svg>
                    </button>
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <div class="mb-4">
                <div class="p-4 bg-gray-200 dark:bg-gray-800 rounded shadow dark:shadow-lg relative">
                    <div class={`flex items-center w-full justify-center mb-4`}>
                        <span class="text-lg">
                            Total <b>{this.state.tab}</b>, for{" "}
                            <span class="underline">
                                {!isNaN(this.state.selected_day_range)
                                    ? `${this.state.selected_day_range} days`
                                    : this.state.selected_day_range.toLowerCase()}
                            </span>
                            :{" "}
                            <strong class="rounded p-1 px-2 bg-brand-pink text-white shadow">
                                {this._return_stats_total()}
                            </strong>
                        </span>
                    </div>
                    <span
                        class={`absolute right-0 top-0 mr-4 mt-4 animate-spin transition-opacity ${
                            this.state.loading ? "opacity-100" : "opacity-0"
                        }`}
                        title="Loading"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                        </svg>
                    </span>
                    <ColumnChart
                        data={this._return_chart_data()}
                        stacked={true}
                        download={true}
                        legend={false}
                    />
                    <div
                        class={`flex items-center w-full justify-between mt-4 ${
                            this._return_stats_total() > 0
                                ? "mb-4 pb-4 border-b"
                                : ""
                        }`}
                    >
                        <span class="text-xs">
                            Data shown for{" "}
                            {!isNaN(this.state.selected_day_range)
                                ? `${this.state.selected_day_range} days`
                                : this.state.selected_day_range.toLowerCase()}
                            .
                        </span>
                        <div class="relative flex flex-row items-center">
                            <div class="mr-2">
                                {this._render_tab_switcher()}
                            </div>
                            {this._render_date_picker_button()}
                            {this._render_date_picker_options()}
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        {this._render_tab_section()}
                    </div>
                </div>
            </div>
        );
    }
}
